import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Title = makeShortcode("Title");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`{/* `}<Title text="Projects" mdxType="Title" /></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lekoarts.de/en/projects/docma-award-super-short-story-telling-about-harry-potter"
        }}>{`Super Short Story Telling: Harry Potter`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.lekoarts.de/en/projects/private-instagram-project-proprius"
        }}>{`Instagram Project "Proprius"`}</a>{`*/}`}</li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      